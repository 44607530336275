<template>
  <router-link :to="toRoute">
    <v-btn class="mb-4 ml-0 mr-3 add-button" color="green" dark>
     {{ label }}
     <v-icon right>add</v-icon>
    </v-btn>
  </router-link>
</template>

<script>
export default {
  props: ["toRoute", "label"]
}
</script>

<style>
@media only screen and (max-width: 568px) {
  .add-button{
    width:100%;
  }
}
</style>
