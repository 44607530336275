<template>
  <div>
    <backButton />
    <v-row>
      <v-col xs="12">
        <v-form ref="form" id="editPageItemForm" lazy-validation>
          <template v-if="page && selectedItem">
            <v-row>
              <v-col xs="12">
                <h2
                  v-if="
                    this.$route.params.itemId == 0 ||
                    this.$route.params.itemId == undefined
                  "
                  class="mb-3"
                >
                  {{ $t("Add new item to") }} {{ page.Name[currentLanguage] }}
                </h2>
                <h2 v-else class="mb-3">
                  {{ $t("Edit item in") }} {{ page.Name[currentLanguage] }}
                </h2>
                <v-alert
                  v-if="
                    objectValidationErrors.length > 0 && userIsAdministrator
                  "
                  class="mt-2 mb-4"
                  color="warning"
                  icon="warning"
                  :value="true"
                >
                  <ul class="ml-4">
                    <li v-for="error in objectValidationErrors" :key="error">
                      {{ error }}
                    </li>
                  </ul>
                </v-alert>
              </v-col>
            </v-row>

            <v-row >
              <template v-for="(item, index) in selectedItem.Data">
                <pageElement
                  :key="index"
                  :item="item"
                  :websiteId="page.WebsiteId.$oid"
                  :pageId="page._id.$oid"
                  :objectName="index"
                  :colsWidth="item.colWidth ? item.colWidth : 12"
                />
              </template>
            </v-row>
          </template>
          <template v-else-if="error.length == 0">
            <preloader></preloader>
          </template>

          <cancelConfirmButtons
            :confirmFunction="savePageContent"
            :loading="saving"
            :confirmLabel="$t('Save')"
          />

          <v-alert
            type="success"
            v-if="success.length > 0"
            class="mt-2"
            icon="check"
            :value="true"
          >
            {{ success }}
          </v-alert>
          <v-alert
            v-if="error.length > 0"
            class="mt-2"
            color="error"
            icon="warning"
            :value="true"
          >
            {{ error }}
          </v-alert>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import request from "../../request";
import auth from "../../auth";
import helpers from "../../helpers";
import Vue from "vue";

export default {
  data() {
    return {
      error: "",
      success: "",
      selectedItem: null,
      page: null,
      saving: false,
      objectValidationErrors: "",
      userIsAdministrator: auth.userIsAdministrator(),
    };
  },
  created() {
    this.getPage();
  },
  watch: {
    "$route.params.id": function () {
      this.getPage();
    },
  },
  computed: {
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
  },
  methods: {
    getPage() {
      request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          if (res.data.Content == null) {
            res.data.Content = [];
            res.data.Content[0] = {};
          }

          if (res.data.ContentDefinition == null) {
            res.data.ContentDefinition = {};
          }

          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);

          if (!this.$route.params.itemId) {
            this.selectedItem = {
              PageId: this.page._id,
              WebsiteId: this.page.WebsiteId,
              Data: this.page.ContentDefinition,
            };
          } else {
            this.getContent();
          }
        } else {
          this.error = res.message;
        }
      });
    },
    getContent() {
      request.get("/content/" + this.$route.params.itemId, null, (res) => {
        if (res.success) {
          this.selectedItem = res.data;
          this.objectValidationErrors =
            helpers.pageItemDefinitionEqualToContentDefinition(
              this.selectedItem.Data,
              this.page.ContentDefinition
            );
        } else {
          this.error = res.message;
        }
      });
    },
    savePageContent() {
      this.error = "";

      var formCustomElementsValid =
        helpers.validateFormPageElements("editPageItemForm");
      var formIsValid = this.$refs.form.validate();

      if (formCustomElementsValid && formIsValid) {
        this.saving = true;
        this.selectedItem.DisplayLabel = helpers.getDisplayLabel(
          this.selectedItem.Data
        );

        request.put("/content", this.selectedItem, (res) => {
          this.saving = false;

          if (res.success) {
            if (
              this.page.SynchronizationPutUrl &&
              this.page.SynchronizationPutUrl.length > 0
            ) {
              request.put(
                this.page.SynchronizationPutUrl,
                this.selectedItem,
                (res) => {
                  if (!res.success) {
                    this.error = res.message;
                  }
                }
              );
            }
            this.$router.go(-1);
          } else {
            this.error = res.message;
          }
        });
      } else {
        this.error = Vue.i18n.translate(
          "Please make sure all text fields have a value"
        );
      }
    },
  },
};
</script>

<style>
#editPageItemForm .textInput.error,
#editPageItemForm .editr--content.error {
  background-color: transparent !important;
}

#editPageItemForm .editr--content.error {
  border-width: 1px;
  border-style: solid;
}
</style>
