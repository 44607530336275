export default {
  hideMessageTimeout: 2000,
  objectTypes: ["text","richtext","image","croppedimage","gallery","datepicker","dropdown", "boolean", "files", "content", "number", "time","custom"],
  contentLanguages: ["Nl","Fr","En"],
  languages: ["Nl","Fr"],
  logoPos: "new-logo.png",
  logoNeg: "new-logo-white.png",
  websiteName: "Veilinghuis De Wit",
  primaryColor: "#3f3d56",
  secondaryColor: "#f5f5f5",
  mailto: "soren@streamliners.be",
  apiUrl: "https://vdw.streamliners.app",
}
