<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-text-field
        v-model="fistname"
        :rules="nameRules"
        label="Voornaam"
        required
        class="mr-2"
      ></v-text-field>
      <v-text-field
        v-model="lastname"
        :rules="nameRules"
        label="Achternaam"
        required
        class="ml-2"
      ></v-text-field>
    </v-row>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mailadres"
      required
    ></v-text-field>
    <div v-if="checkbox">
      <v-row>
        <v-text-field
          v-model="adress"
          :rules="addressRules"
          label="Adres"
          required
          class="mr-2"
        ></v-text-field>
        <v-text-field
          v-model="country"
          :rules="countryRules"
          label="Land"
          required
          class="ml-2"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="city"
          :rules="cityRules"
          label="Stad"
          required
          class="mr-2"
        ></v-text-field>

        <v-text-field
          v-model.number="postalCode"
          :rules="postalCodeRules"
          label="Postcode"
          required
          class="ml-2"
        ></v-text-field>
      </v-row>

      <v-text-field
        v-model="tel"
        :rules="telRules"
        label="Telefoon"
        required
      ></v-text-field>
    </div>
    <v-layout justify-end>
      <v-btn light class="mr-4" @click="dialog = false"> Sluit </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="validate">
        Nieuwe klant toevoegen
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import request from "../../../request";

export default {
  props: ["checkbox"],
  data() {
    return {
      valid: true,
      detailValid: true,
      fistname: null,
      lastname: null,
      email: null,
      adress: null,
      country: null,
      city: null,
      postalCode: null,
      tel: null,
      formItems: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [(v) => !!v || "Name is required"],
      telRules: [
        (v) =>
          /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
            v
          ) || "Tel must be valid",
      ],
      postalCodeRules: [(v) => /[0-9]$/.test(v) || "Not a postal Code"],
      addressRules: [
        (v) => (/[A-Za-z]$/.test(v) && !!v) || "not the correct address",
      ],
      cityRules: [(v) => (/[A-Za-z]$/.test(v) && !!v) || "not a valid city"],
      countryRules: [(v) => /[A-Za-z]$/.test(v) || "not a valid country"],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$refs.form.reset();

        this.$emit("input", value);
      },
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      this.newClient = {
        Data: {
          AgreedPrivacyPolicy: {
            Options: [],
            Properties: [],
            Title: "Akkoord met privacy policy",
            Translate: true,
            Type: "boolean",
            Value: true,
          },
          City: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: "false",
            Title: "Gemeente of stad",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.city,
            },
          },
          CompanyName: {
            Title: "Bedrijfsnaam",
            Value: { Nl: "", Fr: "", En: "" },
            Type: "text",
            Translate: false,
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: false,
          },
          Country: {
            Options: [],
            Properties: [],
            Required: "false",
            Title: "Land",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.country,
            },
          },
          Email: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: "false",
            ShowFilter: false,
            Title: "E-mailadres",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.email,
            },
          },
          FirstName: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Readonly: true,
            Required: "false",
            Title: "Voornaam",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.fistname,
            },
          },
          LastName: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: "false",
            Title: "Familienaam",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.lastname,
            },
          },
          Phone: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: "false",
            Title: "Telefoonnummer",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.tel,
            },
          },
          Street: {
            Options: [],
            Properties: [],
            Required: "false",
            Title: "Straat en nummer",
            Translate: false,
            Type: "text",
            Value: {
              En: "",
              Fr: "",
              Nl: this.adress,
            },
          },
          Vat: {
            Options: [],
            Properties: [],
            Required: false,
            Title: "BTW",
            Translate: false,
            Type: "text",
            Value: { Nl: "", Fr: "", En: "" },
          },
          WantsNewsletters: {
            Options: [],
            Properties: [],
            Title: "Wil nieuwsbrieven ontvangen",
            Translate: true,
            Type: "boolean",
            Value: false,
          },
          Zip: {
            Title: "Postcode",
            Value: {
              En: "",
              Fr: "",
              Nl: this.postalCode,
            },
            Type: "text",
            Translate: false,
            Options: [],
            Properties: [],
            Required: false,
          },
        },
        DisplayLabel: `${this.fistname},${this.lastname},${this.email},${this.adress},${this.country},${this.city},${this.postalCode},${this.tel}`,
        PageId: {
          $oid: "5fa3b4eaae990f3950fe068a",
        },
        WebsiteId: {
          $oid: "5ecb872f1049fd42d495d789",
        },
      };
      //todo ask help with call
      request.put("/content", this.newClient, (res) => {
        if (res.success) {
          this.dialog = false;
        } else {
          this.error = res.message;
        }
      });
    },
  },
};
</script>

<style>
</style>