<template>
   <v-container fill-height>
     <v-layout align-center>
       <v-flex xs12>
         <h3 class="display-3 mb-2">{{ $t('Welcome') }}, {{ user.FirstName }}</h3>
         <span class="subheading" v-if="userIsAdministrator">
           {{ $t('Using this application administrator') }}
         </span>
         <span class="subheading" v-else>
           {{ $t('Using this application') }}
         </span>
         <v-divider class="my-3"></v-divider>
         <div class="dashboardTitle mb-3">
           <div class="dashboardIconContainer">
              <v-icon class="mt-2">account_circle</v-icon>
           </div>
           <div class="dashboardActionContainer">
             <a :href="'mailto:'+mailto+'?Subject='+$t('Request user access')+'&Body='+$t('Request user access body')">{{ $t('Request user access?')}}</a>
           </div>
         </div>
         <div class="dashboardTitle mb-3">
           <div class="dashboardIconContainer">
              <v-icon>add_to_queue</v-icon>
           </div>
           <div class="dashboardActionContainer">
              <a :href="'mailto:'+mailto+'?Subject='+$t('Request a new feature')+'&Body='+$t('Request a new feature body')">{{ $t('Request a new feature?')}}</a>
           </div>
         </div>
         <div class="dashboardTitle mb-3">
           <div class="dashboardIconContainer">
              <v-icon>warning</v-icon>
           </div>
           <div class="dashboardActionContainer">
              <a :href="'mailto:'+mailto+'?Subject='+$t('Having trouble')+'&Body='+$t('Having trouble body')">{{ $t('Having trouble?')}}</a>
           </div>
         </div>
       </v-flex>
     </v-layout>
   </v-container>
</template>

<script>
import auth from '../auth'
import config from '../config'

export default {

  data () {
    return {
      user: auth.getUser(),
      userIsAdministrator: auth.userIsAdministrator(),
      mailto: config.mailto
    }
  }
}
</script>

<style>
  .dashboardIconContainer{
    min-width:30px;
    float:left;
    display:table-cell;
  }

  .dashboardActionContainer{
    display:table-cell;
  }

  .dashboardTitle{
    display:table;
    font-size:20px;
  }

  .dashboardIconContainer i{
    position:relative;
    top:6px;
  }

  @media only screen and (max-width: 568px) {
    .display-3{
      font-size:30px !important;
    }

    .dashboardActionContainer a{
      font-size:15px !important;
      line-height:1.4 !important;
      display:block;
    }
  }
</style>
