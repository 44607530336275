<template>
  <v-dialog
    v-model="dialog"
    persistent
    :retain-focus="false"
    light
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" icon>
        <v-icon> {{ dailogItems.icon }} </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ dailogItems.title }}</span>
        <v-layout justify-end align-center>
          <v-checkbox
            v-if="dailogItems.needsDetailsCheckbox"
            v-model="checkbox"
            label="Toon details"
          ></v-checkbox>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-container>
          <userDialog
            v-model="dialog"
            v-if="dailogItems.AddNewUser"
            :checkbox="checkbox"
          />
          <lotDialog
            v-model="dialog"
            v-if="dailogItems.AddNewLot"
            :auctions="dailogItems.auctions"
            :checkbox="checkbox"
          />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dailogItems: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      checkbox: false,
    };
  },

  methods: {},
};
</script>

<style>
</style>
