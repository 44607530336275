<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field v-model="artNr" label="Artikelnummer" required></v-text-field>
    <v-text-field v-model="desc" label="Omschrijving" required></v-text-field>
    <v-textarea v-model="descDetail" label="Omschrijving detail"></v-textarea>
    <v-row>
      <v-col class="pl-0" cols="6">
        <v-text-field
          v-model.number="height"
          step="0.01"
          label="Hoogte"
          type="number"
        >
        </v-text-field>
      </v-col>
      <v-col class="pr-0" cols="6">
        <v-text-field
          v-model.number="width"
          step="0.01"
          label="Breedte"
          type="number"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-0" cols="6">
        <v-text-field
          v-model.number="minAmount"
          step="0.01"
          label="Minimum schatting"
          type="number"
        >
        </v-text-field>
      </v-col>
      <v-col class="pr-0" cols="6">
        <v-text-field
          v-model.number="maxAmount"
          step="0.01"
          label="Maximum schatting"
          type="number"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-select
        v-if="auctions"
        :items="auctions"
        v-model="auction"
        label="Selecteer een veiling"
        hide-no-data
        item-value="_id"
        item-text="DisplayLabel"
      >
      </v-select>
    </v-row>
    <v-row>
      <v-select
        v-if="categories"
        :items="categories"
        v-model="categorie"
        label="Selecteer een categorie"
        hide-no-data
        item-value="_id"
        item-text="DisplayLabel"
      >
      </v-select>
    </v-row>
    <v-layout justify-end>
      <v-btn light class="mr-4" @click="dialog = false"> Close </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="validate">
        Validate
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import request from "../../../request";

export default {
  props: ["auctions"],
  data() {
    return {
      auction: "",
      categories: null,
      categorie: "",
      artNr: "",
      desc: "",
      descDetail: "",
      height: "",
      width: "",
      minAmount: "",
      maxAmount: "",
      valid:false
    };
  },
  created() {
    request.get("/pagecontentsearch/5ff47c747660aa17e5b7b7e4", null, (res) => {
      if (res.success) {
        this.categories = res.data;
      }
    });
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$refs.form.reset();
        this.$emit("input", value);
      },
    },
    
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      this.newLot = {
        Data: {
          Active: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            ShowFilter: true,
            Title: "Actief",
            Translate: false,
            Type: "boolean",
            Value: false,
          },
          ArticleNumber: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: "false",
            Title: "Artikelnummer",
            Translate: false,
            Type: "number",
            Value: this.artNr,
          },
          Auction: {
            AddToTableView: true,
            LinkedPage: {
              $oid: "5fa3b4eaae990f3950fe0687",
            },
            LoadItemsWhenCreated: true,
            Options: [],
            Properties: [],
            Required: false,
            ShowFilter: true,
            Title: "Veiling",
            Translate: true,
            Type: "content",
            Value: this.auction,
          },
          Category: {
            AddToTableView: true,
            LinkedPage: { $oid: "5ff47c747660aa17e5b7b7e4" },
            LoadItemsWhenCreated: true,
            Options: [],
            Properties: [],
            Required: false,
            ShowFilter: true,
            Title: "Categorie",
            Translate: true,
            Type: "content",
            Value: this.categorie,
          },
          Description: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: false,
            Title: "Omschrijving",
            Translate: false,
            Type: "text",
            Value: { Nl: this.desc, Fr: "", En: "" },
          },
          DescriptionDetail: {
            AddToTableView: true,
            Options: [],
            Properties: [],
            Required: false,
            Title: "Omschrijving detail",
            Translate: false,
            Type: "richtext",
            Value: { Nl: this.descDetail, Fr: "", En: "" },
          },
          Height: {
            Options: [],
            Properties: [],
            Required: false,
            Title: "Hoogte",
            Translate: false,
            Type: "number",
            Value: this.height,
          },
          Images: {
            AddAltTags: true,
            Options: [],
            Properties: [],
            Title: "Afbeeldingen",
            Translate: false,
            Type: "gallery",
            Value: [{ Name: "", Alt: { Nl: "", Fr: "", En: "" } }],
          },
          MaximumPrice: {
            Options: [],
            Properties: [],
            Required: false,
            Title: "Maximum schatting",
            Translate: false,
            Type: "number",
            Value: this.maxAmount,
          },
          MinimumPrice: {
            Options: [],
            Properties: [],
            Required: false,
            Title: "Minimum schatting",
            Translate: false,
            Type: "number",
            Value: this.minAmount,
          },
          Width: {
            Options: [],
            Properties: [],
            Required: false,
            Title: "Breedte",
            Translate: false,
            Type: "number",
            Value: this.width,
          },
        },
        DisplayLabel: `${this.artNr},${this.desc},${this.descDetail}`,
        PageId: { $oid: "5fa3b4eaae990f3950fe0688" },
        WebsiteId: { $oid: "5ecb872f1049fd42d495d789" },
      };
      request.put("/content", this.newLot, (res) => {
        if (res.success) {
          this.dialog=false
        } else {
          this.error = res.message;
        }
      });
    },
  },
};
</script>

<style>
</style>