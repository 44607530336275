<template>
  <v-col class="py-0" :lg="colsWidth" :md="colsWidth" xs="12">
    <v-card class="mb-4">
      <v-card-text>
        <!-- Add title -->
        <template v-if="editMode">
          <v-row>
            <v-col xs="9" sm="10">
              <v-text-field
                :label="objectName"
                v-model="item.Title"
                required
                :rules="[
                  (v) => !!v || $t('Name is required'),
                  (v) =>
                    v.length >= 2 ||
                    $t('Minimum required characters is') + ' 2',
                ]"
                validate-on-blur
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col xs="2" sm="1">
              <v-flex xs2 sm1 class="text-right">
                <v-btn
                  class="mt-4"
                  color="error"
                  icon
                  v-on:click="deleteItem()"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-flex>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <h3 class="mb-3">{{ item.Title }}</h3>
        </template>

        <!-- If the item is text -->
        <template v-if="item.Type === 'text'">
          <template v-if="item.Translate || item.Translate == undefined">
            <v-select
              :items="itemLanguages"
              v-model="itemDefaultLanguage"
              single-line
              class="pageElementLanguageSelect"
            ></v-select>
          </template>

          <template
            v-for="(translatedValue, translatedValueIndex) in item.Value"
          >
            <template v-if="itemDefaultLanguage == translatedValueIndex">
              <textarea
                :key="translatedValueIndex"
                class="textInput"
                v-model="item.Value[translatedValueIndex]"
                rows="2"
                :required="item.Required"
                :disabled="item.ReadOnly"
              />
            </template>
          </template>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mt-4 mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Translate"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Translate item')"
                ></v-switch>
                <v-switch
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is number -->
        <template v-if="item.Type === 'number'">
          <v-layout justify-center align-center>
            <input
              step="0.01"
              type="number"
              v-model.number="item.Value"
              rows="2"
              :required="item.Required"
              class="textInput"
              :disabled="item.ReadOnly"
            />

            <h1 class="ml-2" v-if="item.IsPercentage">%</h1>
          </v-layout>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mt-4 mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>

                <v-switch
                  v-model="item.ReadOnly"
                  :label="$t('Read-only')"
                  hide-details="auto"
                  class="float-left mr-3"
                ></v-switch>
                <v-switch
                  v-model="item.IsPercentage"
                  :label="$t('Is percentage')"
                  hide-details="auto"
                  class="float-left mr-3"
                >
                </v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is rich text -->
        <template v-else-if="item.Type === 'richtext'">
          <template v-if="item.Translate || item.Translate == undefined">
            <v-select
              :items="itemLanguages"
              v-model="itemDefaultLanguage"
              single-line
              class="pageElementLanguageSelectRichText"
            ></v-select>
          </template>

          <template
            v-for="(translatedValue, translatedValueIndex) in item.Value"
          >
            <template v-if="itemDefaultLanguage == translatedValueIndex">
              <editor
                api-key="f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh"
                v-model="item.Value[translatedValueIndex]"
                :key="tinyId + translatedValueIndex"
                :required="item.Required"
                :init="{
                  height: 500,
                  menubar: false,
                  language: editorLanguage,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic underline strikethrough | table image link | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | removeformat',
                }"
                :disabled="item.ReadOnly"
              />
            </template>
          </template>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Translate"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Translate item')"
                ></v-switch>
                <v-switch
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  :label="$t('Read-only')"
                  class="float-left mr-3"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is a boolean -->
        <template v-else-if="item.Type === 'boolean'">
          <v-switch v-model="item.Value" :disabled="item.ReadOnly"></v-switch>

          <template v-if="editMode">
            <hr />

            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is a date picker -->
        <template v-else-if="item.Type === 'datepicker'">
          <v-layout row wrap>
            <v-flex>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menu"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="item.Value"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :label="$t('Pick a date')"
                    v-model="item.Value"
                    prepend-icon="event"
                    readonly
                    :rules="dateRule"
                    validate-on-blur
                    :clearable="!item.Required"
                    :disabled="item.ReadOnly"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.Value"
                  locale="nl-be"
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">{{
                    $t("Cancel")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(item.Value)"
                    >{{ $t("Confirm") }}</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is time -->
        <template v-else-if="item.Type === 'time'">
          <v-row>
            <v-col>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menu"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="item.Value"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :label="$t('Pick a time')"
                    v-model="item.Value"
                    prepend-icon="event"
                    readonly
                    :rules="dateRule"
                    validate-on-blur
                    :clearable="!item.Required"
                    :disabled="item.ReadOnly"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="item.Value"
                  locale="nl-be"
                  format="24hr"
                  scrollable
                  :use-seconds="item.ShowSeconds"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">{{
                    $t("Cancel")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(item.Value)"
                    >{{ $t("Confirm") }}</v-btn
                  >
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Read-only')"
                ></v-switch>
                <v-switch
                  v-model="item.ShowSeconds"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Show seconds')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is dropdown -->
        <template v-else-if="item.Type === 'dropdown'">
          <v-select
            :items="item.Options"
            v-model="item.Value"
            :label="$t('Select')"
            single-line
            menu-props="bottom"
            item-value="Id"
            item-text="Value"
            :rules="dropdownRule"
            validate-on-blur
            :clearable="!item.Required"
            :disabled="item.ReadOnly"
          ></v-select>

          <template v-if="editMode">
            <h4>{{ $t("Modify select options") }}</h4>

            <template v-for="(option, optionIndex) in item.Options">
              <v-chip
                :key="optionIndex"
                class="ml-0"
                close
                @input="removeOption(index)"
              >
                {{ option.Value }}
              </v-chip>
            </template>
            <br />
            <v-row>
              <v-col sm="12" lg="6">
                <v-text-field
                  :label="$t('Option name')"
                  v-model="newOptionName"
                  required
                  :error="addOptionFieldHasError"
                  :rules="addOptionFieldRule"
                  class="mr-xs-0 mr-sm-3"
                ></v-text-field>
              </v-col>
              <v-col sm="12" lg="3">
                <v-btn
                  class="
                    mt-3
                    mr-3
                    float-left
                    edit-page-action-button
                    dropdown-action-button
                  "
                  color="green"
                  dark
                  v-on:click="addOption"
                >
                  {{ $t("Add option") }}
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="12" lg="12">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="2" lg="2">
                    <v-text-field
                      v-model.number="item.colWidth"
                      hide-details="auto"
                      type="number"
                      max="12"
                      min="1"
                      dense
                      :label="$t('Column width')"
                      class="mw-w"
                    ></v-text-field>
                    <v-switch
                      v-model="item.Required"
                      class="float-left mr-3"
                      hide-details="auto"
                      :label="$t('Field is required')"
                    ></v-switch>
                    <v-switch
                      v-model="item.ReadOnly"
                      hide-details="auto"
                      class="float-left mr-3"
                      :label="$t('Read-only')"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is an image -->
        <template v-else-if="item.Type === 'image'">
          <!-- If there is already an image saved -->
          <template v-if="item.Value.Name">
            <div class="imageContainer">
              <img
                class="pageImage"
                :src="
                  serverUrl + '/images/' + websiteId + '/' + item.Value.Name
                "
              />

              <v-layout
                v-if="!item.ReadOnly"
                row
                wrap
                class="editContainer mt-2 mt-2 pl-3 pr-3"
              >
                <template v-if="item.AddAltTags || item.AddAltTags == null">
                  <template
                    v-if="item.Translate || item.Translate == undefined"
                  >
                    <v-flex lg3 sm3 xs12 pr-4>
                      <v-select
                        :items="itemLanguages"
                        v-model="itemDefaultLanguage"
                        single-line
                        class="altSelect"
                      ></v-select>
                    </v-flex>
                  </template>
                  <v-flex lg7 sm7 xs10 pr-4>
                    <template
                      v-for="(translatedValue, translatedValueIndex) in item
                        .Value.Alt"
                    >
                      <template
                        v-if="itemDefaultLanguage == translatedValueIndex"
                      >
                        <input
                          :key="translatedValueIndex"
                          type="text"
                          class="textInput"
                          placeholder="Alt tag"
                          v-model="item.Value.Alt[translatedValueIndex]"
                        />
                      </template>
                    </template>
                  </v-flex>
                </template>
                <v-flex lg2 sm2 xs2>
                  <v-btn
                    color="error"
                    class="customRemoveButton"
                    icon
                    v-on:click="deleteImage(item)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </template>
          <!-- If no image was saved -->
          <template v-else-if="!item.ReadOnly">
            <h3 v-if="file">
              <v-icon>insert_photo</v-icon>
              {{ file.name }}
              <v-btn color="error" icon v-on:click="deleteSelectedImage()">
                <v-icon>delete</v-icon>
              </v-btn>
            </h3>

            <input
              :ref="'fileInput' + objectName"
              class="fileInput"
              type="file"
              v-on:change="setSelectedFile($event.target.files)"
            />

            <v-btn
              class="ml-0 mt-3 mr-3 edit-page-action-button"
              @click="$refs['fileInput' + objectName].click()"
              color="primary"
              >{{ $t("Choose an image") }}</v-btn
            >
            <v-btn
              color="blue-grey"
              class="ml-0 mt-3 white--text edit-page-action-button"
              :disabled="!file || uploading || this.uploadError.length > 0"
              :loading="uploading"
              v-on:click="submitFile(item)"
              >{{ $t("Upload") }}<v-icon right dark>cloud_upload</v-icon></v-btn
            >

            <v-alert
              v-if="uploadError.length > 0"
              class="mt-2"
              color="error"
              icon="warning"
              :value="true"
            >
              {{ uploadError }}
            </v-alert>
          </template>
          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Translate"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Translate item')"
                ></v-switch>
                <v-switch
                  v-model="item.AddAltTags"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Add alt tags')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is a cropped image -->
        <template v-else-if="item.Type === 'croppedimage'">
          <template v-if="item.Value.Name">
            <div class="imageContainer">
              <img
                class="pageImage"
                :src="
                  serverUrl + '/images/' + websiteId + '/' + item.Value.Name
                "
              />

              <v-layout
                v-if="!item.ReadOnly"
                row
                wrap
                class="editContainer mt-2 mt-2 pl-3 pr-3"
              >
                <template v-if="item.AddAltTags || item.AddAltTags == null">
                  <template
                    v-if="item.Translate || item.Translate == undefined"
                  >
                    <v-flex lg3 sm3 xs12 pr-4>
                      <v-select
                        :items="itemLanguages"
                        v-model="itemDefaultLanguage"
                        single-line
                        class="altSelect"
                      ></v-select>
                    </v-flex>
                  </template>
                  <v-flex lg7 sm7 xs10 pr-4>
                    <template
                      v-for="(translatedValue, translatedValueIndex) in item
                        .Value.Alt"
                    >
                      <template
                        v-if="itemDefaultLanguage == translatedValueIndex"
                      >
                        <input
                          :key="translatedValueIndex"
                          type="text"
                          class="textInput"
                          placeholder="Alt tag"
                          v-model="item.Value.Alt[translatedValueIndex]"
                        />
                      </template>
                    </template>
                  </v-flex>
                </template>
                <v-flex lg2 sm2 xs2>
                  <v-btn
                    color="error"
                    class="customRemoveButton"
                    icon
                    v-on:click="deleteImage(item)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </template>
          <!-- If no image was saved -->
          <template v-else-if="!item.ReadOnly">
            <v-layout row wrap class="mt-2">
              <v-flex lg6 sm12>
                <VueCropper
                  v-show="selectedImageToCrop"
                  class="mb-3"
                  ref="cropper"
                  :src="selectedImageToCrop"
                  :aspectRatio="item.Width / item.Height"
                >
                </VueCropper>
              </v-flex>
            </v-layout>

            <input
              :ref="'fileInput' + objectName"
              class="fileInput"
              type="file"
              @change="onCroppedImageSelect"
            />

            <v-btn
              class="ml-0 mt-3 mr-3 edit-page-action-button"
              @click="$refs['fileInput' + objectName].click()"
              color="primary"
              >{{ $t("Choose an image") }}</v-btn
            >
            <v-btn
              color="blue-grey"
              class="ml-0 mt-3 white--text edit-page-action-button"
              :disabled="!file || uploading || this.uploadError.length > 0"
              :loading="uploading"
              v-on:click="submitCroppedImage(item)"
              >{{ $t("Upload") }}<v-icon right dark>cloud_upload</v-icon></v-btn
            >

            <v-alert
              v-if="uploadError.length > 0"
              class="mt-2"
              color="error"
              icon="warning"
              :value="true"
            >
              {{ uploadError }}
            </v-alert>
          </template>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('Width in pixels')"
                  v-model="item.Width"
                  dense
                  class="float-left mr-3"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('Height in pixels')"
                  v-model="item.Height"
                  dense
                  class="float-left mr-3"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Translate"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Translate item')"
                ></v-switch>
                <v-switch
                  v-model="item.AddAltTags"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Add alt tags')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is an image gallery -->
        <template v-else-if="item.Type === 'gallery'">
          <!-- There are images in the gallery -->
          <template v-for="(galleryItem, index) in item.Value">
            <div
              :key="index"
              v-if="galleryItem.Name != ''"
              class="imageContainer gallery mb-4"
            >
              <img
                class="galleryImage"
                :src="
                  serverUrl + '/images/' + websiteId + '/' + galleryItem.Name
                "
              />

              <v-layout
                v-if="!item.ReadOnly"
                row
                wrap
                class="editContainer mt-2 mt-2 pl-3 pr-3"
              >
                <template v-if="item.AddAltTags || item.AddAltTags == null">
                  <template
                    v-if="item.Translate || item.Translate == undefined"
                  >
                    <v-flex lg3 sm3 xs12 pr-4>
                      <v-select
                        :items="itemLanguages"
                        v-model="itemDefaultLanguage"
                        single-line
                        class="altSelect"
                      ></v-select>
                    </v-flex>
                  </template>
                  <v-flex lg7 sm7 xs10 pr-4>
                    <template
                      v-for="(
                        translatedValue, translatedValueIndex
                      ) in galleryItem.Alt"
                    >
                      <template
                        v-if="itemDefaultLanguage == translatedValueIndex"
                      >
                        <input
                          :key="translatedValueIndex"
                          type="text"
                          class="textInput"
                          placeholder="Alt tag"
                          v-model="galleryItem.Alt[translatedValueIndex]"
                        />
                      </template>
                    </template>
                  </v-flex>
                </template>
                <v-flex lg2 sm2 xs2>
                  <v-btn
                    color="error"
                    class="customRemoveButton"
                    icon
                    v-on:click="deleteFile(item.Value, index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </template>

          <template v-for="(image, index) in this.files">
            <h3 :key="index">
              <v-icon>insert_photo</v-icon>
              {{ image.name }}
              <v-btn color="error" icon v-on:click="deleteSelectedFile(index)">
                <v-icon>delete</v-icon>
              </v-btn>
            </h3>
          </template>

          <input
            v-if="!item.ReadOnly"
            :ref="'galleryFileInput' + objectName"
            class="fileInput"
            multiple
            type="file"
            v-on:change="setSelectedFiles($event.target.files)"
          />
          <v-btn
            v-if="!item.ReadOnly"
            class="ml-0 mt-3 mr-3 edit-page-action-button"
            @click="$refs['galleryFileInput' + objectName].click()"
            color="primary"
            >{{ $t("Choose images") }}</v-btn
          >
          <v-btn
            v-if="!item.ReadOnly"
            color="blue-grey"
            class="ml-0 mt-3 white--text edit-page-action-button"
            :disabled="!files || uploading || this.uploadError.length > 0"
            :loading="uploading"
            v-on:click="submitGalleryFiles(item)"
            >{{ $t("Upload") }}<v-icon right dark>cloud_upload</v-icon></v-btn
          >

          <v-alert
            v-if="uploadError.length > 0"
            class="mt-2"
            color="error"
            icon="warning"
            :value="true"
          >
            {{ uploadError }}
          </v-alert>

          <template v-if="editMode">
            <hr class="mt-5" />
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Translate"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Translate item')"
                ></v-switch>
                <v-switch
                  v-model="item.AddAltTags"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Add alt tags')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is a list of files -->
        <template v-else-if="item.Type === 'files'">
          <!-- There are files in the list -->
          <template v-for="(file, index) in item.Value">
            <div :key="index" v-if="file != ''">
              <h3>
                <v-icon>attach_file</v-icon>
                {{ file }}
                <v-btn
                  v-if="!item.ReadOnly"
                  color="error"
                  icon
                  v-on:click="deleteFile(item.Value, index)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </h3>
            </div>
          </template>

          <template v-for="(file, index) in this.files">
            <h3 :key="index">
              <v-icon>attach_file</v-icon>
              {{ file }}
              <v-btn
                v-if="!item.ReadOnly"
                color="error"
                icon
                v-on:click="deleteFile(item.Value, index)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </h3>
          </template>

          <input
            v-if="!item.ReadOnly"
            :ref="'filesInput' + objectName"
            class="fileInput"
            multiple
            type="file"
            v-on:change="setSelectedFiles($event.target.files)"
          />
          <v-btn
            v-if="!item.ReadOnly"
            class="mt-3 ml-0 mr-3 edit-page-action-button"
            @click="$refs['filesInput' + objectName].click()"
            color="primary"
            >{{ $t("Choose files") }}</v-btn
          >
          <v-btn
            v-if="!item.ReadOnly"
            color="blue-grey"
            class="mt-3 ml-0 white--text edit-page-action-button"
            :disabled="!files || uploading || this.uploadError.length > 0"
            :loading="uploading"
            v-on:click="submitFiles(item)"
            >{{ $t("Upload") }}<v-icon right dark>cloud_upload</v-icon></v-btn
          >

          <v-alert
            v-if="uploadError.length > 0"
            class="mt-2"
            color="error"
            icon="warning"
            :value="true"
          >
            {{ uploadError }}
          </v-alert>

          <template v-if="editMode">
            <hr class="mt-5" />

            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is a list of content -->
        <template v-else-if="item.Type === 'content'">
          <!-- There is content in the list -->
          <template v-for="(content, index) in item.Value">
            <div :key="index" v-if="content != ''">
              <h3>
                <v-icon>description</v-icon>
                {{ content }}
                <v-btn
                  color="error"
                  icon
                  v-on:click="deleteContent(item.Value, index)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </h3>
            </div>
          </template>

          <v-autocomplete
            mb-3
            v-model="item.LinkedContent"
            :items="searchResults"
            :loading="searchingContent"
            :search-input.sync="searchContentWatch"
            :multiple="item.MultipleItems"
            :chips="item.MultipleItems"
            :deletable-chips="item.MultipleItems"
            item-text="DisplayLabel"
            item-value="_id"
            :rules="dropdownRule"
            :clearable="!item.Required"
            hide-no-data
            :disabled="item.ReadOnly"
            @change="searchContentWatch = ''"
          ></v-autocomplete>

          <template v-if="editMode">
            <hr />
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-bind:items="getSharedPagesNotAssigned()"
                  single-line
                  v-model="item.LinkedPage"
                  item-value="_id"
                  :label="$t('Select a page')"
                  class="altSelect"
                >
                  <template slot="selection" slot-scope="data">
                    <div class="input-group__selections__comma">
                      {{ data.item.Name[currentLanguage] }}
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.Name[currentLanguage] }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="2" lg="2">
                    <v-text-field
                      v-model.number="item.colWidth"
                      hide-details="auto"
                      type="number"
                      max="12"
                      min="1"
                      dense
                      :label="$t('Column width')"
                      class="mw-w"
                    ></v-text-field>
                    <v-switch
                      v-model="item.LoadItemsWhenCreated"
                      :label="$t('Load items when created')"
                      hide-details="auto"
                      class="float-left mr-3"
                    ></v-switch>
                    <v-switch
                      v-model="item.MultipleItems"
                      class="float-left mr-3"
                      hide-details="auto"
                      :label="$t('Multiple items')"
                    ></v-switch>
                    <v-switch
                      v-model="item.Required"
                      class="float-left mr-3"
                      hide-details="auto"
                      :label="$t('Field is required')"
                    ></v-switch>
                    <v-switch
                      v-model="item.ReadOnly"
                      class="float-left mr-3"
                      hide-details="auto"
                      :label="$t('Read-only')"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- If the item is a list of custom -->
        <template v-else-if="item.Type === 'custom'">
          <component
            :is="customComponent"
            :componentData="item"
            @saveComponent="saveItem"
          ></component>
        </template>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import request from "../../request";
import config from "../../config";
import helpers from "../../helpers";

import Vue from "vue";
import { mapGetters } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";

export default {
  data() {
    return {
      uploadError: "",
      file: null,
      files: null,
      uploading: false,
      menu: false,
      newOptionName: "",
      addOptionFieldHasError: false,
      addOptionFieldRule: [],
      newProperty: "",
      addPropertyHasError: false,
      addPropertyRule: [],
      searchingContent: false,
      searchResults: [],
      selectedSearchResult: null,
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      selectedPage: null,
      serverUrl: helpers.getServerUrl(),
      searchContentWatch: null,
      selectedImageToCrop: null,
      tinyId: 0,
    };
  },
  components: { VueCropper, editor: Editor },
  props: ["editMode", "item", "websiteId", "pageId", "objectName", "colsWidth"],
  created() {
    if (this.item.LoadItemsWhenCreated) {
      this.searchContent("");
    } else if (this.item.LinkedContent) {
      this.getContent(this.item.LinkedContent);
    }
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
    editorLanguage: {
      get: function () {
        if (!Vue.i18n.locale()) {
          return "nl";
        }
        return Vue.i18n.locale() == "Nl" ? "nl" : "fr_FR";
      },
    },
    dateRule() {
      if (this.item.Required) {
        return [(v) => !!v || Vue.i18n.translate("Date is required")];
      }

      return [];
    },
    fieldRule() {
      if (this.item.Required) {
        return [(v) => !!v || Vue.i18n.translate("Field is required")];
      }

      return [];
    },
    dropdownRule() {
      if (this.item.Required) {
        return [(v) => !!v || Vue.i18n.translate("You must select an item")];
      }

      return [];
    },
    customComponent() {
      return () => import("@/components/custom/" + this.objectName + ".vue");
    },
  },
  watch: {
    searchContentWatch: function (searchValue) {
      if (!this.item.LoadItemsWhenCreated) {
        if (searchValue != "") {
          this.searchContent(searchValue);
        }
      }
    },
    editorLanguage: function () {
      this.tinyId += 1;
    },
    "item.Value": {
      handler(val) {
        switch (this.item.Type) {
          case "datepicker":
            this.item.ValueSort = parseInt(moment(val).format("YYYYMMDD"));
            break;
          case "richtext":
            this.item.ValueSort = val[this.currentLanguage].replace(
              /<[^>]*>?/gm,
              ""
            );
            break;
          case "dropdown":
            this.item.ValueSort = this.item.Options.find(
              (o) => o.Id == val
            ).Value;
            break;
        }
      },
      deep: true,
    },
    "item.LinkedContent": {
      handler(val) {
        if (Array.isArray(val)) {
          var valueSort = "";

          for (var valIndex in val) {
            for (var searchResultIndex in this.searchResults) {
              if (
                val[valIndex].$oid ==
                this.searchResults[searchResultIndex]._id.$oid
              ) {
                valueSort +=
                  this.searchResults[searchResultIndex].DisplayLabel + ", ";
              }
            }
          }
          this.item.ValueSort = valueSort.substring(0, valueSort.length - 2);
        } else if (this.searchResults.find((o) => o._id == val)) {
          this.item.ValueSort = this.searchResults.find(
            (o) => o._id == val
          ).DisplayLabel;
        }
      },
      deep: true,
    },
  },
  methods: {
    saveItem(event) {
      this.item.Value = event;
    },
    deleteImage(item) {
      item.Value.Name = "";
    },
    deleteSelectedImage() {
      this.file = null;
    },
    submitFile(item) {
      this.uploading = true;
      this.uploadError = "";

      let formData = new FormData();
      formData.append("files", this.file);

      request.post("/content/imageupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["fileInput" + this.objectName].value = null;

        if (res.success) {
          item.Value.Name = res.data[0];
          this.file = null;
        } else {
          this.uploadError = res.message;
        }
      });
    },
    setSelectedFile(fileList) {
      this.uploadError = "";
      this.file = fileList[0];
    },
    deleteFile(files, index) {
      files.splice(index, 1);
    },
    setSelectedFiles(fileList) {
      this.uploadError = "";
      this.files = Array.prototype.slice.call(fileList);
    },
    submitGalleryFiles(item) {
      this.uploading = true;
      this.uploadError = "";

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      request.post("/content/imageupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["galleryFileInput" + this.objectName].value = null;

        if (res.success) {
          if (item.Value == "") {
            item.Value = [];
          } else {
            item.Value = item.Value.filter((i) => i.Name != "");
          }

          for (let i = 0; i < res.data.length; i++) {
            item.Value[item.Value.length] = {
              Name: res.data[i],
              Alt: { Nl: "", Fr: "", En: "" },
            };
          }
          this.getItemLanguages();
          this.getItemDefaultLanguage();
          this.files = null;
        } else {
          this.uploadError = res.message;
        }
      });
    },
    submitFiles(item) {
      this.uploading = true;
      this.uploadError = "";

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }

      request.post("/content/fileupload/" + this.pageId, formData, (res) => {
        this.uploading = false;
        this.$refs["filesInput" + this.objectName].value = null;

        if (res.success) {
          item.Value = res.data;

          this.files = null;
        } else {
          this.uploadError = res.message;
        }
      });
    },
    deleteSelectedFile(index) {
      this.files.splice(index, 1);
    },
    deleteItem() {
      this.$emit("removeItem");
    },
    addOption() {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.item.Options.push({
          Id: this.item.Options.length + 1,
          Value: this.newOptionName,
        });
      } else {
        this.addOptionFieldRule = [
          () => Vue.i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
    addProperty() {
      this.addPropertyHasError = false;
      this.addPropertyRule = [];

      if (this.newProperty.length > 1) {
        this.item.Properties.push({
          Id: this.item.Properties.length + 1,
          Value: this.newProperty,
        });
      } else {
        this.addPropertyRule = [
          () => Vue.i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addPropertyHasError = true;
      }
    },
    removeOption(index) {
      this.item.Options.splice(index, 1);
    },
    removeProperty(index) {
      this.item.Properties.splice(index, 1);
    },
    getItemLanguages() {
      return config.contentLanguages;
    },
    getItemDefaultLanguage() {
      return config.contentLanguages[0];
    },
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    searchContent(searchValue) {
      if (this.searchingContent) return;
      if (!this.item.LinkedPage) return;
      this.searchingContent = true;

      request.get(
        "/pagecontentsearch/" +
          this.item.LinkedPage.$oid +
          "?searchValue=" +
          searchValue,
        null,
        (res) => {
          if (res.success) {
            if (this.item.MultipleItems) {
              var concatSearchResults = this.searchResults.concat(res.data);

              for (var i = 0; i < concatSearchResults.length; ++i) {
                for (var j = i + 1; j < concatSearchResults.length; ++j) {
                  if (
                    concatSearchResults[i]._id.$oid ===
                    concatSearchResults[j]._id.$oid
                  )
                    concatSearchResults.splice(j--, 1);
                }
              }

              this.searchResults = concatSearchResults;
            } else {
              this.searchResults = res.data;
            }
          } else {
            this.error = res.message;
          }

          this.searchingContent = false;
        }
      );
    },
    getContent(id) {
      if (Array.isArray(id)) {
        var getContentUrls = [];
        for (var idIndex in id) {
          getContentUrls.push("/content/" + id[idIndex].$oid);
        }
        request.getAll(getContentUrls, null, (res) => {
          this.searchResults = [];
          for (var resIndex in res) {
            if (res[resIndex].success) {
              this.searchResults.push(res[resIndex].data);
            } else {
              this.error = res[resIndex].message;
            }
          }
        });
      } else {
        request.get("/content/" + id.$oid, null, (res) => {
          if (res.success) {
            this.searchResults = [];
            this.searchResults.push(res.data);
          } else {
            this.error = res.message;
          }
        });
      }
    },
    onCroppedImageSelect(e) {
      this.file = e.target.files[0];
      this.mime_type = this.file.type;

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedImageToCrop = event.target.result;
          this.$refs.cropper.replace(this.selectedImageToCrop);
        };
        reader.readAsDataURL(this.file);
      }
    },
    submitCroppedImage(item) {
      this.uploading = true;
      this.uploadError = "";

      this.$refs.cropper
        .getCroppedCanvas({ width: this.item.Width })
        .toBlob((blob) => {
          let formData = new FormData();
          formData.append("files", blob, this.file.name);

          request.post(
            "/content/imageupload/" + this.pageId,
            formData,
            (res) => {
              this.uploading = false;
              this.$refs["fileInput" + this.objectName].value = null;

              if (res.success) {
                item.Value.Name = res.data[0];
                this.file = null;
              } else {
                this.uploadError = res.message;
              }
            }
          );
        }, this.mime_type);
    },
  },
};
</script>

<style>
.mw-w {
  min-width: 2rem;
}
.editr--toolbar a.vw-btn-separator {
  display: none;
}

.fileInput {
  display: none;
}

.pageImage {
  max-width: 100%;
  max-height: 500px;
}

.galleryImage {
  max-width: 100%;
  max-height: 350px;
}

.imageContainer {
  position: relative;
  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}

.tox-button {
  background-color: var(--v-primary-base) !important;
  text-transform: uppercase !important;
  color: var(--v-accent-lighten5) !important;
  border-color: var(--v-primary-base) !important;
}

.tox-button--secondary,
.tox-button--icon {
  background-color: var(--v-secondary-base) !important;
  color: var(--v-accent-darken5) !important;
  border-color: var(--v-secondary-base) !important;
}

.tox-textfield:focus,
.tox-listbox:focus {
  border-color: var(--v-primary-base) !important;
}

@media only screen and (max-width: 568px) {
  .editr--toolbar {
    height: auto;
    display: table;
  }

  .editr--content {
    clear: both;
  }

  .editr--toolbar div[title] {
    float: left;
  }
}
</style>
