<template>
  <div class="mt-2">
    <v-alert v-if="error.length > 0" color="error" icon="warning" :value="true">
        {{ error }}
    </v-alert>

    <template v-if="users">
      <addButton toRoute="usersoverview/createedituser" :label="$t('Add user')"/>

      <v-card>
        <v-layout custom-overview-row row v-for="(user,index) in users" :key="user.Name">
         <v-flex :key="index" custom-overview-row-title lg10 sm9 xs6 pt-3 pl-3 pb-3 subheading>
           {{ user.Email }}
         </v-flex>
         <v-flex custom-overview-row-actions lg2 sm3 xs6 text-xs-right>
           <router-link :to="{ name: 'createEditUser', params: { id: user._id.$oid }}">
              <v-btn class="mr-1" icon color="orange">
                <v-icon>edit</v-icon>
              </v-btn>
           </router-link>
           <router-link :to="{ name: 'deleteUser', params: { id: user._id.$oid }}">
             <v-btn class="mr-1" icon color="red">
               <v-icon>delete</v-icon>
             </v-btn>
           </router-link>
         </v-flex>
        </v-layout>
      </v-card>
    </template>
    <template v-else-if="error.length == 0">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
import request from '../../request'

export default {
  data () {
    return {
      error: "",
      users: null
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers(){
      request.get("/users", null, (res) => {
        if (res.success) {
          this.users = res.data;
        }else{
          this.error = res.message
        }
      })
    }
  }
}
</script>

<style>

</style>
