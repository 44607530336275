<template>
  <v-layout class="full-height" justify-center align-center fill-height>
    <v-row no-gutters>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>

      <v-col>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="272"
          height="207.151"
          viewBox="0 0 472 407.151"
        >
          <g
            id="Group_14"
            data-name="Group 14"
            transform="translate(-7789 -2589.849)"
          >
            <g
              id="Path_9"
              data-name="Path 9"
              transform="translate(0 2.441)"
              fill="none"
            >
              <path
                d="M7893.86,2644.454s17.485-67.974,64.133-52.433,17.85,78.021,17.85,78.021-24.307-15.993-35.018-18.792S7893.86,2644.454,7893.86,2644.454Z"
                stroke="none"
              />
              <path
                d="M 7968.24658203125 2648.91015625 C 7970.70458984375 2638.906005859375 7972.08740234375 2626.591064453125 7968.20361328125 2617.622314453125 C 7965.63427734375 2611.6884765625 7960.98388671875 2607.774169921875 7953.56884765625 2605.3037109375 C 7950.39794921875 2604.247314453125 7947.36181640625 2603.711669921875 7944.544921875 2603.711669921875 C 7929.45166015625 2603.711669921875 7919.037109375 2619.875244140625 7913.1611328125 2632.66064453125 C 7924.43017578125 2634.101318359375 7937.9560546875 2636.031005859375 7944.36474609375 2637.705810546875 C 7950.87744140625 2639.407470703125 7960.22998046875 2644.274658203125 7968.24658203125 2648.91015625 M 7975.84326171875 2670.04296875 C 7975.84326171875 2670.04296875 7951.53662109375 2654.0498046875 7940.8251953125 2651.2509765625 C 7930.11376953125 2648.451904296875 7893.8603515625 2644.4541015625 7893.8603515625 2644.4541015625 C 7893.85986328125 2644.4541015625 7911.34619140625 2576.48291015625 7957.99365234375 2592.021484375 C 8004.6416015625 2607.5625 7975.84326171875 2670.04296875 7975.84326171875 2670.04296875 Z"
                stroke="none"
                fill="#000"
              />
            </g>
            <g
              id="Path_10"
              data-name="Path 10"
              transform="translate(163.007)"
              fill="none"
            >
              <path
                d="M7993.171,2651.066s-19.259-74.988-64.939-58.974-23.965,92.179-23.965,92.179,30.527-27.85,38.561-33.205S7993.171,2651.066,7993.171,2651.066Z"
                stroke="none"
              />
              <path
                d="M 7913.7255859375 2657.0048828125 C 7922.06884765625 2649.6806640625 7930.81787109375 2642.24658203125 7935.06201171875 2639.417236328125 C 7940.10302734375 2636.056396484375 7947.1689453125 2634.685546875 7959.4501953125 2634.685546875 C 7963.68017578125 2634.685546875 7968.1630859375 2634.86083984375 7972.46630859375 2635.118408203125 C 7965.5888671875 2620.2099609375 7954.568359375 2603.84912109375 7940.7216796875 2603.84912109375 C 7938.26025390625 2603.84912109375 7935.61669921875 2604.33837890625 7932.86328125 2605.303466796875 C 7913.5595703125 2612.070556640625 7911.9169921875 2637.56787109375 7913.7255859375 2657.0048828125 M 7904.2666015625 2684.271240234375 C 7904.2666015625 2684.271240234375 7882.5517578125 2608.105224609375 7928.23193359375 2592.091796875 C 7973.9150390625 2576.07958984375 7993.17138671875 2651.06591796875 7993.17138671875 2651.06591796875 C 7993.17138671875 2651.06591796875 7950.861328125 2645.7099609375 7942.82763671875 2651.06591796875 C 7934.7939453125 2656.421630859375 7904.2666015625 2684.271240234375 7904.2666015625 2684.271240234375 Z"
                stroke="none"
                fill="#000"
              />
            </g>
            <g id="Path_7" data-name="Path 7" fill="none">
              <path
                d="M7794.378,2858.751l47.344-165.7s27.153-64.054,87.726-58.484,66.143,71.016,66.143,71.016l-.611,173.59s-29.327-64.281-92.685-69.155S7794.378,2858.751,7794.378,2858.751Z"
                stroke="none"
              />
              <path
                d="M 7977.16748046875 2825.610595703125 L 7977.58740234375 2706.426025390625 C 7976.892578125 2700.15771484375 7970.5166015625 2656.41455078125 7927.79931640625 2652.486572265625 C 7925.8369140625 2652.30615234375 7923.92236328125 2652.218994140625 7922.0498046875 2652.218994140625 C 7882.6044921875 2652.218994140625 7861.63427734375 2693.03857421875 7858.6611328125 2699.279052734375 L 7827.0390625 2809.95654296875 C 7845.22509765625 2799.874755859375 7868.021484375 2791.700927734375 7894.13916015625 2791.700927734375 C 7897.27294921875 2791.700927734375 7900.45556640625 2791.819580078125 7903.6748046875 2792.067138671875 C 7935.5244140625 2794.517333984375 7959.79833984375 2809.373046875 7977.16748046875 2825.610595703125 M 7994.97900390625 2879.1689453125 C 7994.97900390625 2879.1689453125 7965.65185546875 2814.887939453125 7902.29443359375 2810.01416015625 C 7838.9423828125 2805.1416015625 7794.3779296875 2858.7509765625 7794.3779296875 2858.750732421875 L 7841.7216796875 2693.04638671875 C 7841.72216796875 2693.04638671875 7868.890625 2628.9970703125 7929.44775390625 2634.562255859375 C 7990.0205078125 2640.132080078125 7995.59033203125 2705.57861328125 7995.59033203125 2705.57861328125 L 7994.97900390625 2879.1689453125 Z"
                stroke="none"
                fill="#000"
              />
            </g>
            <g
              id="Path_8"
              data-name="Path 8"
              transform="translate(259.622)"
              fill="none"
            >
              <path
                d="M7993.361,2858.751l-46.819-165.7s-26.853-64.054-86.754-58.484-65.41,71.016-65.41,71.016l1.152,173.761s28.455-64.452,91.11-69.325S7993.361,2858.751,7993.361,2858.751Z"
                stroke="none"
              />
              <path
                d="M 7813.17138671875 2825.20166015625 C 7830.22607421875 2809.1083984375 7854.0009765625 2794.498779296875 7885.244140625 2792.068359375 C 7888.4384765625 2791.81982421875 7891.59619140625 2791.700927734375 7894.705078125 2791.700927734375 C 7920.40771484375 2791.700927734375 7942.8623046875 2799.771240234375 7960.81640625 2809.766845703125 L 7929.56884765625 2699.174560546875 C 7926.625 2692.809326171875 7906.29736328125 2652.218994140625 7867.103515625 2652.218994140625 C 7865.263671875 2652.218994140625 7863.38232421875 2652.3056640625 7861.4541015625 2652.48486328125 C 7819.81396484375 2656.356689453125 7813.22265625 2698.900634765625 7812.3837890625 2706.418212890625 L 7813.17138671875 2825.20166015625 M 7795.52978515625 2879.339599609375 L 7794.3779296875 2705.57861328125 C 7794.3779296875 2705.57861328125 7799.88623046875 2640.132080078125 7859.78759765625 2634.562255859375 C 7919.68408203125 2628.9921875 7946.5419921875 2693.04638671875 7946.5419921875 2693.04638671875 L 7993.361328125 2858.750732421875 C 7993.361328125 2858.750732421875 7949.30419921875 2805.136474609375 7886.64013671875 2810.01416015625 C 7823.98486328125 2814.887939453125 7795.52978515625 2879.339599609375 7795.52978515625 2879.339599609375 Z"
                stroke="none"
                fill="#000"
              />
            </g>
            <g
              id="Ellipse_24"
              data-name="Ellipse 24"
              transform="translate(7789 2789)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            >
              <ellipse cx="103.5" cy="104" rx="103.5" ry="104" stroke="none" />
              <ellipse cx="103.5" cy="104" rx="96" ry="96.5" fill="none" />
            </g>
            <g
              id="Ellipse_25"
              data-name="Ellipse 25"
              transform="translate(8054 2789)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            >
              <ellipse cx="103.5" cy="104" rx="103.5" ry="104" stroke="none" />
              <ellipse cx="103.5" cy="104" rx="96" ry="96.5" fill="none" />
            </g>
            <line
              id="Line_11"
              data-name="Line 11"
              x2="63"
              transform="translate(7993.5 2730.5)"
              fill="none"
              stroke="#000"
              stroke-width="17"
            />
            <line
              id="Line_12"
              data-name="Line 12"
              x2="64"
              transform="translate(7992.5 2808.5)"
              fill="none"
              stroke="#000"
              stroke-width="17"
            />
          </g>
        </svg>
      </v-col>
      <v-col>
        <v-layout column justify-center fill-height>
          <h1>404</h1>
          <h2>{{ $t("UH OH! You're lost.") }}</h2>
          <p>
            {{
              $t(
                "The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the dashboard."
              )
            }}
          </p>
          <v-btn @click="goToDashboard()">
            {{ $t("Go to home page") }}
          </v-btn>
        </v-layout>
      </v-col>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),
  },
  methods: {
    goToDashboard() {
      if (this.selectedWebsite.DashboardPageId.$oid!='000000000000000000000000') {
        this.$router.push({
          name: "editPage",
          params: { id: this.selectedWebsite.DashboardPageId.$oid },
        });
        this.$router.go(1);
      } else {
        this.$router.push({ name: "dashboard" });
        this.$router.go(1);
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  height: 80vh;
}
.hoverItem {
  animation: float 1s infinite ease-in-out alternate;
}

.hoverItem2 {
  animation: float 1s infinite ease-in-out alternate;
  animation-delay: 0.8s;
}
.hoverItem3 {
  animation: float 1s infinite ease-in-out alternate;
  animation-delay: 0.5s;
}
.hoverItem4 {
  animation: float 1s infinite ease-in-out alternate;
  animation-delay: 0.2s;
}
.hoverItem5 {
  animation: float 1s infinite ease-in-out alternate;
  animation-delay: 0.4s;
}

@keyframes float {
  100% {
    transform: translateY(-20px);
  }
}
</style>