//initial state
const state = {
  all: [],
  selected: null,
  selectedId: null
}

//getters
const getters = {
  allWebsites: state => state.all,
  selectedWebsite: state => state.selected
}

//actions
const actions = {
  setAllWebsites ({ commit }, websites) {
    commit('setWebsites', websites)
  },
  setSelectedWebsite ({ commit }, website) {
    commit('setSelectedWebsite', website)
  },
  setSelectedWebsiteById({ commit }, _id) {
    commit('setSelectedWebsiteById', _id)
  }
}

//mutations
const mutations = {
  setWebsites (state, setWebsites) {
    state.all = setWebsites

    var stateSelectedWebsiteExists = false;

    if(state.selected){
      //If a website was selected set as the selected website
      for(var i = 0; i < state.all.length; i++) {
          if (state.all[i]._id.$oid == state.selected._id.$oid) {
              stateSelectedWebsiteExists = true
              state.selected = state.all[i]
              break
          }
      }

      if(!stateSelectedWebsiteExists){
        state.selected = null
      }
    }else if(!state.selected && state.selectedId){
      //If a website id was selected set as the selected website
      for(var stateWebsitesCounter = 0; stateWebsitesCounter < state.all.length; stateWebsitesCounter++) {
          if (state.all[stateWebsitesCounter]._id.$oid == state.selectedId.$oid) {
              stateSelectedWebsiteExists = true
              state.selected = state.all[stateWebsitesCounter]
              break
          }
      }

      if(!stateSelectedWebsiteExists){
        state.selected = null
      }
    }
  },
  setSelectedWebsite(state, selectedWebsite){
    //set the selected website with with website Object
    state.selected = selectedWebsite
  },
  setSelectedWebsiteById(state, _id){
    state.selectedId = _id

    //Set selected website by id
    var selectedWebsiteExists = false;

    for(var i = 0; i < state.all.length; i++) {
        if (state.all[i]._id.$oid == _id.$oid) {
            selectedWebsiteExists = true
            state.selected = state.all[i]
            break
        }
    }

    if(!selectedWebsiteExists){
      state.selected = null
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
