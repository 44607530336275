<template>
  <div>
    <backButton />

    <template v-if="selectedItem">
      <div v-html="$t('Delete page item', {'label': selectedItem.DisplayLabel})" />
      <br />

      <cancelConfirmButtons :confirmFunction="deletePageItem" :loading="deleting" :confirmLabel="$t('Delete')" />
    </template>
    <template v-else-if="error.length == 0">
      <preloader></preloader>
    </template>

    <v-alert v-if="error.length > 0" color="error" icon="warning" :value="true">
        {{ error }}
    </v-alert>
  </div>
</template>

<script>
import request from '../../request'

export default {
  data () {
    return {
      error: "",
      selectedItem: null,
      deleting:false,
      page:null,
    }
  },
  created () {
    this.getContent()
    request.get("/page/" + this.$route.params.id, null, (res) => {
      if (res.success) {
        this.page=  res.data
      }}
    )
  },
  methods: {
    deletePageItem(){
        this.deleting = true

        if (this.page.SynchronizationDeleteUrl && this.page.SynchronizationDeleteUrl.length > 0) {
          request.delete(this.page.SynchronizationDeleteUrl + "/" + this.$route.params.itemId, null, (res) => {
            if (!res.success) {
              this.error = res.message
            }
            request.delete("/content/"+ this.$route.params.itemId, null, (res) => {
              this.deleting = false

              if (res.success) {
                this.$router.go(-1)
              }else{
                this.error = res.message
              }
            })
          })
        }else{
          request.delete("/content/"+ this.$route.params.itemId, null, (res) => {
            this.deleting = false

            if (res.success) {
              this.$router.go(-1)
            }else{
              this.error = res.message
            }
          })
        }
    },
    getContent(){
      request.get("/content/"+ this.$route.params.itemId, null, (res) => {
        if (res.success) {
          this.selectedItem = res.data
          this.$store.dispatch('setSelectedWebsiteById', this.selectedItem.WebsiteId)
        }else{
          this.error = res.message
        }
      })
    }
  }
}
</script>

<style>

</style>
