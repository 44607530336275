import request from './request'

export default {
  login (email, pass, cb) {
    cb = arguments[arguments.length - 1]

    request.post("/login",{username:email,password:pass},(res) => {
      if (res.success) {
        this.setUser(res.data)
        this.onChange(true)
      }

      if (cb) cb(res)
    })
  },

  logOut (cb) {
    this.onChange(false)
    request.delete('/logout',{},(res) => {
      cb = arguments[arguments.length - 1]

      if (res.success) {
        this.deleteUser()
      }

      if (cb) cb(res)
    })
  },

  loggedIn () {
    return !!localStorage.user
  },

  onChange () {},

  getUser () {
    if(localStorage.user == null){
      return null
    }

    return JSON.parse(localStorage.user)
  },

  setUser(user) {
    localStorage.user = JSON.stringify(user)
  },

  deleteUser(){
    delete localStorage.user
  },

  userIsAdministrator(){
    if(localStorage.user == null){
      return false
    }

    return this.getUser().Administrator
  },

  userLanguage(){
    if(localStorage.user == null){
      return ""
    }

    return this.getUser().Language
  }
}
