<template>
  <div>
    <v-layout custom-overview-row row wrap>
     <v-flex custom-overview-row-title sm9 subheading>
       <v-layout row wrap>
         <v-flex sm1>
           <v-select
             :items="pageLanguages"
             v-model="pageDefaultLanguage"
             single-line
             class="pageLanguageSelector pr-3"
           ></v-select>
         </v-flex>
         <v-flex sm11>
           <template v-for="(translatedValue, translatedValueIndex) in page.Name">
               <template v-if="pageDefaultLanguage == translatedValueIndex">
                 <v-text-field
                  :key=translatedValueIndex
                  :label="$t('Name')"
                  v-model="page.Name[translatedValueIndex]"
                  single-line
                  required
                  :rules="[(v) => !!v || $t('Name is required'),(v) => v.length >= 2 || $t('Minimum required characters is') + ' 2']"
                  validate-on-blur
                  ></v-text-field>
               </template>
           </template>
         </v-flex>
       </v-layout>
     </v-flex>
     <v-flex custom-overview-row-actions sm3 pl-3>
       <router-link :to="{ name: 'editPageDetails', params: { name: page.Name[currentLanguage], websiteId: websiteId, id: page._id.$oid  }}">
           <v-btn icon color="orange">
             <v-icon>edit</v-icon>
           </v-btn>
       </router-link>
       <v-btn icon color="red" v-on:click="removePage()">
         <v-icon>delete</v-icon>
       </v-btn>
     </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: ["page", "websiteId"],
  data () {
    return {
      pageLanguages: null,
      pageDefaultLanguage: null
    }
  },
  computed: {
    currentLanguage:{
       get: function () {
         return Vue.i18n.locale()
       }
    }
  },
  created () {
    this.pageLanguages = Object.keys(this.page.Name)
    this.pageDefaultLanguage = this.pageLanguages[0]
  },
  methods:{
    removePage(){
      this.$emit('removePage');
    }
  }
}
</script>
