import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import config from '../config'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css' 

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: config.primaryColor,
        secondary: config.secondaryColor
      },
    },
    options: {
      customProperties: true,
    },

  },
  icons: {
    iconfont: 'mdi',
  }
});
