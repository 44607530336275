<template>
  <div class="mt-5 text-right">
    <v-btn class="ml-0 mr-3 cancel-button" v-on:click="goBack">{{ $t('Cancel') }}</v-btn>
    <v-btn color="primary save-button" v-on:click="confirmFunction" :loading="loading">{{ confirmLabel }}</v-btn>
  </div>
</template>

<script>
export default {
  props: ["confirmFunction", "confirmLabel", "loading"],
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
</style>
