import config from './config'
import auth from './auth'

export default {
  getDisplayLabel: function (item) {
    let keys = Object.keys(item)
    let displayLabel = ""

    let userLanguage = auth.userLanguage()
    userLanguage = userLanguage.charAt(0).toUpperCase() + userLanguage.slice(1);

    for (var i = 0; i < keys.length; i++) {
      if (item[keys[i]].Type == "text" || item[keys[i]].Type == "richtext") {
        if (item[keys[i]].Value[userLanguage] != undefined) {
          if (item[keys[i]].Value[userLanguage] == "") {
            if (item[keys[i]].Value[Object.keys(item[keys[i]].Value)[0]] != "") {
              displayLabel += this.removeSpecialCharacters(item[keys[i]].Value[Object.keys(item[keys[i]].Value)[0]]) + ", "
            }
          } else if (item[keys[i]].Value[userLanguage] != "") {
            displayLabel += this.removeSpecialCharacters(item[keys[i]].Value[userLanguage]) + ", "
          }
        } else if (item[keys[i]].Value[Object.keys(item[keys[i]].Value)[0]] != "") {
          displayLabel += this.removeSpecialCharacters(item[keys[i]].Value[Object.keys(item[keys[i]].Value)[0]]) + ", "
        }
      }
    }

    if (displayLabel == "") {
      for (var ii = 0; i < keys.length; ii++) {
        if (item[keys[ii]].Type == "datepicker" && item[keys[ii]].Value.length > 0) {
          displayLabel += this.removeSpecialCharacters(item[keys[ii]].Value) + ", "
        }
      }
    }
    if (displayLabel == "") {
      for (var iii = 0; iii < keys.length; iii++) {
        if ((item[keys[iii]].Type == "image" || item[keys[iii]].Type == "croppedimage") && item[keys[iii]].Value.Alt[userLanguage].length > 0) {
          displayLabel += this.removeSpecialCharacters(item[keys[iii]].Value.Alt[userLanguage]) + ", "
        }
      }
    }

    displayLabel = displayLabel.replace(/(?:\r\n|\r|\n)/g, ' ');
    displayLabel = displayLabel.substr(0, displayLabel.length - 2)

    if (displayLabel.length > 75) {
      displayLabel = displayLabel.substring(0, 75 - 3) + "..."
    }

    return this.stripHtml(displayLabel)
  },
  stripHtml: function (html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  },
  removeSpecialCharacters: function (text) {
    text = this.stripHtml(text)
    return text.replace(/[^\w\s]/gi, '')
  },
  validateFormPageElements: function (formId) {
    var textInputsValid = this.validateElement(formId, "textInput")
    var richTextInputsValid = this.validateElement(formId, "quillWrapper")

    return textInputsValid && richTextInputsValid
  },
  validateElement: function (formId, elementClass) {
    var formTextElements = document.getElementById(formId).getElementsByClassName(elementClass)
    var errors = 0;

    for (var i = 0; i < formTextElements.length; i++) {
      var required = false;

      if (formTextElements[i].getAttribute("required") != null) {
        required = formTextElements[i].getAttribute("required").length > 0;
      }

      if (formTextElements[i].value == undefined) {
        var qlEditor = formTextElements[i].getElementsByClassName("ql-editor")[0]
        var classBefore = qlEditor.className

        if (qlEditor.innerText.length <= 1 && required) {
          qlEditor.className += " error"

          errors += 1;
        } else {
          qlEditor.className = classBefore.replace("error", "")
        }
      } else {
        if (formTextElements[i].value.length <= 0 && required) {
          formTextElements[i].className += " error"

          errors += 1;
        } else {
          formTextElements[i].className = elementClass
        }
      }
    }

    return errors == 0
  },
  pageItemDefinitionEqualToContentDefinition(itemData, contentDefinition) {
    let errors = []

    let itemKeys = Object.keys(itemData)
    let contentDefinitionKeys = Object.keys(contentDefinition)

    //check if object should be added
    for (let i = 0; i < contentDefinitionKeys.length; i++) {
      if (!(Object.prototype.hasOwnProperty.call(itemData, contentDefinitionKeys[i]))) {
        errors[errors.length] = "'" + contentDefinition[contentDefinitionKeys[i]].Title + "' is missing"
      } else if (itemData[contentDefinitionKeys[i]].Type != contentDefinition[contentDefinitionKeys[i]].Type) {
        //check if object types are equal
        errors[errors.length] = "'" + contentDefinitionKeys[i].Title + "' type is not equal to " + contentDefinition[contentDefinitionKeys[i]].Type
      } else if (itemData[contentDefinitionKeys[i]].Type == "dropdown") {
        let itemOptions = itemData[contentDefinitionKeys[i]].Options
        let contentDefinitionOptions = contentDefinition[contentDefinitionKeys[i]].Options

        //check if options are not valid in dropdown
        for (let o = 0; o < itemOptions.length; o++) {
          let optionValueExists = false

          for (let oo = 0; oo < contentDefinitionOptions.length; oo++) {
            if (itemOptions[o].Value == contentDefinitionOptions[oo].Value) {
              optionValueExists = true
            }
          }

          if (!optionValueExists) {
            errors[errors.length] = "option '" + itemOptions[o].Value + "' should be removed"
          }
        }

        //check if options should be added in dropdown
        for (let o = 0; o < contentDefinitionOptions.length; o++) {
          let optionValueExists = false

          for (let oo = 0; oo < itemOptions.length; oo++) {
            if (contentDefinitionOptions[o].Value == itemOptions[oo].Value) {
              optionValueExists = true
            }
          }

          if (!optionValueExists) {
            errors[errors.length] = "option '" + contentDefinitionOptions[o].Value + "' should be added"
          }
        }
      }
    }

    //check if object should be removed
    for (let i = 0; i < itemKeys.length; i++) {
      if (!(Object.prototype.hasOwnProperty.call(contentDefinition, itemKeys[i]))) {
        errors[errors.length] = "'" + itemData[itemKeys[i]].Title + "' should be removed"
      }
    }

    if (errors.length == 0) {
      return true
    }

    return errors
  },
  createEmptyValueBasedOnType(type) {
    let value = ""

    if (type == "boolean") {
      value = false
    } else if (type == "text" || type == "richtext") {
      value = {}

      for (var i = 0; i < config.contentLanguages.length; i++) {
        value[config.contentLanguages[i]] = ""
      }
    } else if (type == "image" || type == "croppedimage") {
      value = {
        "Name": "",
        "Alt": {
          "Nl": "",
          "Fr": "",
          "En": ""
        }
      }
    } else if (type == "gallery") {
      value = [
        {
          "Name": "",
          "Alt": {
            "Nl": "",
            "Fr": "",
            "En": ""
          }
        }
      ]
    }

    return value
  },
  firstCharOfStringUpperCase(inputString) {
    return inputString.substr(0, 1).toUpperCase() + inputString.substr(1, inputString.length - 1).toLowerCase()
  },
  sortByKey(key) {
    return function (a, b) {
      return (a.Name[key].localeCompare(b.Name[key]))
    }
  },
  getServerUrl() {
    return window.location.href.indexOf('localhost:8080') >= 0 ? config.apiUrl : '';
  }
}
